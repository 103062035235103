import React, { useEffect, useState } from "react";
import Tabs from "./Tabs";
import { ArticleCardType } from "@/src/types";
import Container from "./Container";

export interface TopPostsProps {
  menuItems: string[];
  posts: { [key: string]: ArticleCardType[] };
  showTabs?: boolean;
  heading?: string;
}

const TopPosts = ({
  menuItems,
  posts,
  showTabs = true,
  heading = "Latest",
}: TopPostsProps) => {
  const [selectedTab, setSelectedTab] = useState("");

  useEffect(() => {
    menuItems && menuItems?.length > 0 && setSelectedTab(menuItems[0]);
  }, [menuItems]);
  if (!menuItems || !posts) {
    return <></>;
  }
  console.log("top posts: ", posts, selectedTab);

  return (
    <div className="flex flex-col gap-2 my-2">
      {showTabs && (
        <Tabs
          tabItems={menuItems.map(function (item, index) {
            return { label: item, value: item, selected: item === selectedTab };
          })}
          setTabItem={(value) => setSelectedTab(value)}
          lhsHeader={heading}
          showTabs={showTabs}
        />
      )}
      <Container posts={posts[selectedTab]} />
    </div>
  );
};

export default TopPosts;
