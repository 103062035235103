import React, { useEffect, useState } from "react";
import TopPosts from ".";
import { ArticleCardType } from "@/src/types";

export interface TopPostsBockProps {
  block: any;
  heroArticleIds: string[];
}

const TopPostsBlock = ({ block, heroArticleIds }: TopPostsBockProps) => {
  const [posts, setPosts] = useState<{ [key: string]: ArticleCardType[] }>({});

  useEffect(() => {
    if (block.frontPageCategory) {
      const newPosts = {};
      //@ts-ignore
      block?.frontPageCategory?.nodes?.map(function (category) {
        if (category) {
          //@ts-ignore
          newPosts[category?.name] = category?.posts?.nodes.filter(
            //@ts-ignore
            (node) => !heroArticleIds.includes(node.id)
          );
        }
      });
      setPosts(newPosts);
    }
  }, [block]);
  console.log("Category posts:", posts);
  return (
    block && (
      <TopPosts
        posts={posts}
        menuItems={Object.keys(posts)}
        showTabs={block.categoriesShowTabs}
        heading={block.categoriesHeading ?? undefined}
      />
    )
  );
};

export default TopPostsBlock;
