import {
  Dialog,
  DialogHeader,
  Typography,
  dialog,
} from "@material-tailwind/react";
import { DialogContent } from "@mui/material";
import { YouTubeEmbed } from "@next/third-parties/google";

export interface VideoModalProps {
  id?: string | null;

  open: boolean;
  setOpen: (open: boolean) => void;
}

const VideoModal = ({ id, open, setOpen }: VideoModalProps) => {
  return (
    <Dialog handler={setOpen} open={open} size="md">
      <DialogContent style={{ minHeight: 320 }}>
        <YouTubeEmbed
          videoid={id ?? ""}
          style="height: 100%;display: flex;"
          height={350}
          params="autoplay=1&autoplay=1"
        />
      </DialogContent>
    </Dialog>
  );
};

export default VideoModal;
