import React from "react";
export interface ItunesProps {
  link: string;
}
const Itunes = ({ link }: ItunesProps) => {
  return (
    <div className="w-full mx-auto flex content-center my-4 mx-auto ">
      <iframe
        allow={"autoplay *; encrypted-media *; fullscreen *; clipboard-write"}
        frameBorder={"0"}
        height={152}
        style={{
          width: "100%",

          overflow: "hidden",
          borderRadius: 10,
        }}
        sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
        // src={link}
        loading="lazy"
        src={`https://open.spotify.com/embed/show/7LT4jzjyZQGui4P8xduwNU?utm_source=generator`}
        title="SCPB Podcast embed"
      />
    </div>
  );
};

export default Itunes;
