import { YoutubeVideo } from "@/src/utils/youtube";
import { useState } from "react";
import FrontPageVideos from "../FrontPageVideos";
import VideoGrid from "../FrontPageVideos/VideoGrid";
import VideoModal from "../../Modal/Video";
import { Typography } from "@material-tailwind/react";
import Image from "next/image";
import { SCPB_LOGO, SCPB_LOGOS } from "@/src/constants";

export interface BlokeVideosProps {
  videos: YoutubeVideo[];
}

const BlokeVideos = ({ videos }: BlokeVideosProps) => {
  const [isPlaying, setIsPlaying] = useState<string | null>(null);

  const handleModalOpen = (open: boolean) => {
    if (!open) {
      setIsPlaying(null);
    }
  };

  return (
    <div
      style={{ backgroundImage: `url(/images/bg.png)` }}
      className="w-full md:py-2 pb-4 md:pb-7 bg-scpb-dark-blue flex justify-center"
    >
      <div className="container front-page-videos-container mt-2 flex flex-col px-2 md:px-0 bloke-videos-container">
        <div
          className={
            "transition-all cursor-pointer py-2 border-b-2 border-transparent text-slate-600 header-container flex gap-2 items-center"
          }
        >
          <div className="w-[120px] h-[2rem] relative">
            <Image src={"/images/BlokeLogo.avif"} fill alt={"Bloke In A Bar"} />
          </div>
          <p className="text-3xl">🤝</p>
          <div className="w-[140px] h-[40px] relative">
            <Image
              src={SCPB_LOGOS["Large Blue White"]}
              fill
              alt={"SCPB Logo"}
            />
          </div>

          <Typography variant="paragraph" className={"font-bold text-xl"}>
            <span className={"text-2xl font-bold text-bloke-orange"}>
              &nbsp;/
            </span>
          </Typography>
        </div>
        <VideoGrid videos={videos.slice(0, 7)} setIsPlaying={setIsPlaying} />
        <VideoModal
          id={isPlaying}
          open={Boolean(isPlaying)}
          setOpen={handleModalOpen}
        />
      </div>
    </div>
  );
};

export default BlokeVideos;
