import { useEffect, useRef, useState } from "react";
import Script from "next/script";
import { List } from "@material-tailwind/react";
import { Episode } from "./Episode";
import { Button } from "@material-tailwind/react";
import { Podcasts } from "@mui/icons-material";
import Image from "next/image";

export type SpofiyShow = {
  uri: string;
  name: string;
  duration_ms: number;
  release_date: string;
  description: string;
};

export type SpotifyContainerProps = {
  shows: SpofiyShow[];
};

export type EmbedControllerType = {
  loadUri: (uri: string) => void;
  play: () => void;
  destroy: () => void;
  addListener: (type: string, func: () => void) => void;
};

export const SpotifyContainer = ({ shows }: SpotifyContainerProps) => {
  const spotifyContainerRef = useRef(null);
  const listRef = useRef(null);
  const [embedController, setEmbedController] =
    useState<EmbedControllerType | null>(null);
  const iframeContainer = useRef(null);
  const iframeParent = useRef(null);
  const [activeURI, setActiveURI] = useState(shows[0]?.uri);
  const [containerHeight, setContainerHeight] = useState(0);
  const [iframeAPI, setIframeAPi] = useState(null);
  const [iframeReady, setIframeReady] = useState(false);

  const cb = (EmbedController: EmbedControllerType) => {
    setEmbedController(EmbedController);
    EmbedController.addListener("ready", () => {
      console.log("Embed Controller: ", iframeParent);
    });
  };

  const updatePlayingEpisode = (uri: string) => {
    setActiveURI(uri);
    embedController?.loadUri(uri);
    embedController?.play();
  };

  const listenForIframe = () => {
    window.onSpotifyIframeApiReady = (IFrameAPI: any) => {
      setIframeAPi(IFrameAPI);
      if (!iframeReady) {
        setIframeReady(true);
      }
    };
  };

  const createIframe = () => {
    const options = {
      width: "100%",
      height: `${containerHeight}px`,
      uri: activeURI,
    };
    //@ts-ignore
    iframeAPI?.createController(iframeContainer.current, options, cb);
  };

  useEffect(() => {
    listenForIframe();
    return () => {
      //   destroyIframe();
    };
  }, []);

  useEffect(() => {
    if (!listRef.current) return;
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { height } = entry.contentRect;
        setContainerHeight(height);
      }
    });

    resizeObserver.observe(listRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    if (listRef.current) {
      //@ts-ignore
      setContainerHeight(listRef.current.offsetHeight);
      if (iframeReady) {
        createIframe();
      }
    }
  }, [listRef, iframeReady]);

  useEffect(() => {
    // createIframe();
  }, [containerHeight]);

  return (
    <div className="spotifyContainer py-2">
      <Script src="https://open.spotify.com/embed/iframe-api/v1"></Script>
      <div
        className="spotify-container flex flex-col md:flex-row shadow-2xl"
        ref={spotifyContainerRef}
      >
        <div
          className="h-full md:h-full spotify-embed-container relative"
          style={{ backgroundColor: `rgb(24, 128, 170)` }}
          ref={iframeParent}
        >
          <div ref={iframeContainer}></div>
          <div className="flex w-full justify-center gap-4 py-4 px-4 md:px-0">
            <Button className="flex items-center gap-3 w-[200px] flex justify-between">
              Apple Podcasts
              <Podcasts />
            </Button>
            <Button className="flex items-center gap-3 w-[200px] flex justify-between">
              Spotify
              <div className="w-8 h-8 relative">
                <Image src={`/images/spotify.svg`} fill alt="spotify logo" />
              </div>
            </Button>
          </div>
        </div>
        <div className="spotify-list-container" ref={listRef}>
          <List>
            {shows.map(function (show, index) {
              return (
                <Episode
                  key={show.uri}
                  active={activeURI === show.uri}
                  title={show.name}
                  date={show.release_date}
                  onClick={() => updatePlayingEpisode(show.uri)}
                  index={index}
                />
              );
            })}
          </List>
        </div>
      </div>
    </div>
  );
};
