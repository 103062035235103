import { useEffect, useState } from "react";
import TopPosts from "../TopPosts.tsx";

export interface RecentArticlesBlockProps {
  block: object;
  posts: object[];
}
const RecentArticlesBlock = ({ block, posts }: RecentArticlesBlockProps) => {
  const [articlePosts, setArticlePosts] = useState({});
  useEffect(() => {
    if (posts) {
      const newPosts = {
        "":
          //@ts-ignore
          posts?.posts?.edges?.map(function (post) {
            return post.node;
          }),
      };
      setArticlePosts(newPosts);
    }
  }, [posts]);

  return <TopPosts menuItems={[]} posts={articlePosts} showTabs={false} />;
};

export default RecentArticlesBlock;
