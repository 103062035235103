import React, { useState } from "react";
import HeroArticles from "./HeroArticles";
import FlagContainer, { FlagArticleItem } from "./FlagContainer";
import TopPosts from "./TopPosts.tsx";
import TopPostsBlock from "./TopPosts.tsx/TopPostsBlock";
import HeroArticleBlock from "./HeroArticleBlock";
import VideosBlock from "./VideosBlock";
import Podcast from "./Podcast";
import BlokeVideos from "../FrontPage/BlokeVideos";
import RecentArticlesBlock from "./RecentArticles";

export enum BLOCK_NAMES {
  CATEGORIES = "TestLayoutTestFlexLayoutFrontPageCategoriesLayout",
  HEROS = "TestLayoutTestFlexLayoutHeroArticlesLayout",
  HERO = "TestLayoutTestFlexLayoutFrontPageHeroArticleLayout",
  VIDEOS = "TestLayoutTestFlexLayoutFrontPageVideosLayout",
  PODCAST = "TestLayoutTestFlexLayoutFrontPagePodcastLayout",
  BLOKE = "TestLayoutTestFlexLayoutBlokeVideosLayout",
  RECENT = "TestLayoutTestFlexLayoutRecentArticlesLayout",
}

export const NonRepeatableArticleContainers = [BLOCK_NAMES.HEROS];

const FullWidthContainers = [
  BLOCK_NAMES.HERO,
  BLOCK_NAMES.BLOKE,
  BLOCK_NAMES.VIDEOS,
];

//@ts-ignore
const Block = ({ block, index, homePageProps, heroArticleIds }) => {
  const blockContent: FlagArticleItem[] = [];

  if (BLOCK_NAMES.HEROS === block.fieldGroupName) {
    block.heroRepeater[0].heroArticlePostContainer.map(function (
      content: any,
      index: number
    ) {
      blockContent.push({
        //@ts-ignore
        position: index,
        article: content,
      });
    });
  }

  const isHero = BLOCK_NAMES.HEROS === block.fieldGroupName && index === 0;
  return (
    <div
      className={`container ${
        isHero ? "md:max-w-[2006px]" : ""
      } mx-auto container-num-${index} ${
        FullWidthContainers.includes(block.fieldGroupName)
          ? "full-width-container"
          : ""
      }`}
    >
      {/* {"ContentTemplate_Frontpagelayouts_FrontPageFlexContent_HeroArticles" ===
        block.fieldGroupName && <HeroArticles block={block} />} */}
      {BLOCK_NAMES.HEROS === block.fieldGroupName && index === 0 && (
        <FlagContainer flags={blockContent} hideSubTextForMobile />
      )}
      {BLOCK_NAMES.HEROS === block.fieldGroupName && index !== 0 && (
        <HeroArticles block={block} />
      )}
      {BLOCK_NAMES.CATEGORIES === block.fieldGroupName && (
        <TopPostsBlock block={block} heroArticleIds={heroArticleIds} />
      )}
      {BLOCK_NAMES.HERO === block.fieldGroupName && (
        <HeroArticleBlock block={block} />
      )}

      {BLOCK_NAMES.VIDEOS === block.fieldGroupName && (
        <VideosBlock block={block} videos={homePageProps.videos} />
      )}
      {BLOCK_NAMES.PODCAST === block.fieldGroupName && (
        <Podcast
          block={block}
          spotifyShows={homePageProps.spotifyShows ?? []}
        />
      )}
      {BLOCK_NAMES.BLOKE === block.fieldGroupName && (
        <BlokeVideos videos={homePageProps.blokeVideos} />
      )}
      {BLOCK_NAMES.RECENT === block.fieldGroupName && (
        <RecentArticlesBlock posts={homePageProps.recentPosts} block={block} />
      )}
    </div>
  );
};

export default Block;
