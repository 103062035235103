import React from "react";
import ArticleCard from "../../cards/ArticleCard2";

//@ts-ignore
const HeroArticles = ({ block }) => {
  let articleIndex = 0;
  let articleCount = 0;

  block?.contentColumn?.forEach(function (c: any, colIndex: number) {
    c?.content?.map(function (c: any, index: number) {
      articleCount++;
    });
  });
  return (
    <div
      className={`hero-articles-container grid gap-2 grid-cols-${articleCount}`}
    >
      {/* // @ts-ignore */}
      {block?.contentColumn.map(function (col: any, index: number) {
        const isHero = col.hero ? true : false;
        // @ts-ignore
        return (
          <>
            {col?.content?.map(function (content: any, colIndex: number) {
              if (!isHero) {
                articleIndex = articleIndex + 1;
              }

              return (
                <div
                  className={`article-card ${
                    isHero ? "article-hero" : `${articleIndex}`
                  }`}
                  key={index}
                >
                  <ArticleCard post={content?.post?.nodes[0]} />
                </div>
              );
            })}
          </>
        );
      })}
    </div>
  );
};

export default HeroArticles;
