import { SpofiyShow, SpotifyContainer } from "../../Podcast/Spotify";
import EmptyPodcasts from "./EmptyPodcasts";

export interface PodcastContainerProps {
  block: {
    podcastEmbedLink: string;
  };
  spotifyShows: object[];
}

const Podcast = ({ block, spotifyShows }: PodcastContainerProps) => {
  return (
    <div className="container px-2 md:px-0">
      {spotifyShows?.length > 0 && (
        <SpotifyContainer shows={spotifyShows as unknown as SpofiyShow[]} />
      )}
      {!spotifyShows ||
        (spotifyShows?.length === 0 && (
          <EmptyPodcasts link={block?.podcastEmbedLink} />
        ))}
    </div>
  );
};

export default Podcast;
