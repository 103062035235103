import { YoutubeVideo } from "@/src/utils/youtube";
import TopVideoCard from "../../cards/VideoCard/TopVideoCard";
import VideoThumbnailCard from "../../cards/VideoCard/VideoThumbnailCard";

export interface VideoGridProps {
  videos: YoutubeVideo[];
  setIsPlaying: (id: string) => void;
}

const VideoGrid = ({ videos, setIsPlaying }: VideoGridProps) => {
  return (
    <div className="front-page-videos-grid">
      {videos.map(function (video, index) {
        return (
          <div className={`w-[100%] video-index-${index}`} key={video.id}>
            {index === 0 && (
              <TopVideoCard
                id={video.id}
                title={video.title}
                image={video.largeThumbnail}
                url={video.id}
                onClick={(id: string) => {
                  setIsPlaying(id);
                }}
              />
            )}
            {index !== 0 && index < 7 && (
              <VideoThumbnailCard
                video={video}
                key={`video-${index}`}
                onClick={(id: string) => {
                  setIsPlaying(id);
                }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default VideoGrid;
