import { YoutubeVideo } from "@/src/utils/youtube";
import React from "react";
import { Card, CardHeader } from "@material-tailwind/react";
import ImageWithFallback from "../FallbackImage";

export interface VideoThumbnailCardProps {
  video: YoutubeVideo;
  onClick: (id: string) => void;
}

const VideoThumbnailCard = ({ video, onClick }: VideoThumbnailCardProps) => {
  return (
    <Card
      className="w-[100%] h-[100%] basic-video-card cursor-pointer"
      onClick={() => onClick(video.id)}
    >
      <CardHeader
        className="relative h-[110px] md:h-[144px] m-0"
        floated={false}
      >
        <i className="fa-solid fa-circle-play text-white bg-scpb-light-blue"></i>
        <ImageWithFallback src={video.thumbnail} alt={video.title} fill />
      </CardHeader>
    </Card>
  );
};

export default VideoThumbnailCard;
