import { ArticleCardType } from "@/src/types";
import React from "react";
import HeroArticleCard from "../../cards/HeroArticleCard";
import ArticleCard from "../../cards/ArticleCard2";
import FlagArticle from "../../cards/FlagArticle";

export interface ContainerProps {
  posts: ArticleCardType[];
}

const GridPositions = {
  hero: "md:row-span-2 md:col-span-3",
  sub: "md:row-span-1 md:col-span-2",
};

const Container = ({ posts }: ContainerProps) => {
  return (
    <div
      className={`top-posts-container grid md:grid md:grid-rows-2 md:grid-cols-5 md:grid-cols-7 grid-flow-col gap-1 md:gap-4 h-full md:min-h-80vh md:max-h-80vh lg:min-h-75vh lg:max-h-75vh mx-2 md:mx-0`}
    >
      {posts?.map(function (post, index) {
        return (
          <div
            className={`${GridPositions[index === 0 ? "hero" : "sub"]} ${
              index === 0 ? "hero" : "sub"
            } ${index > 2 ? "md:hidden lg:block" : ""}`}
            key={`post-${index}`}
          >
            {index === 0 ? (
              <FlagArticle article={post} position={0} key={post.id} />
            ) : (
              <ArticleCard post={post} key={post.id} />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Container;
