import Itunes from "../../Podcast/itunes";

export interface EmptyPodcastsProps {
  link: string;
}

const EmptyPodcasts = ({ link }: EmptyPodcastsProps) => {
  return (
    <>
      <Itunes link={link} />
    </>
  );
};

export default EmptyPodcasts;
