import { ListItem, ListItemSuffix, Typography } from "@material-tailwind/react";
import { Pause, PlayArrow } from "@mui/icons-material";

export type EpisodeType = {
  onClick: () => void;
  active: boolean;
  date: string;
  title: string;
  index: number;
};

function formatTitle(title: String) {
  if (title.split(":").length > 0) {
    return (
      <>
        <b className="font-extrabold">{title.split(":")[0]}:</b>
        {title.split(":")[1]}
      </>
    );
  } else {
    return title;
  }
}

export const Episode = ({
  active,
  onClick,
  date,
  title,
  index,
}: EpisodeType) => {
  return (
    <ListItem
      onClick={onClick}
      className={`py-2 ${index > 2 ? "hidden md:flex" : ""}`}
    >
      <div>
        <Typography variant="small">{date}&nbsp;</Typography>
        <Typography variant="paragraph">{formatTitle(title)}</Typography>
      </div>
      <ListItemSuffix>{active ? <Pause /> : <PlayArrow />}</ListItemSuffix>
    </ListItem>
  );
};
