import { YoutubeVideo } from "@/src/utils/youtube";
import React, { useState } from "react";
import TopVideoCard from "../../cards/VideoCard/TopVideoCard";
import VideoCard from "../../cards/VideoCard";
import FlagVideoCard from "../../cards/VideoCard/FlagVideoCard";
import { Typography } from "@material-tailwind/react";
import VideoThumbnailCard from "../../cards/VideoCard/VideoThumbnailCard";
import VideoModal from "../../Modal/Video";
import VideoGrid from "./VideoGrid";

export interface FrontPageVideosProps {
  header: string;
  videos: YoutubeVideo[];
}

const FrontPageVideos = ({ header, videos }: FrontPageVideosProps) => {
  const [isPlaying, setIsPlaying] = useState<string | null>(null);
  const handleModalOpen = (open: boolean) => {
    if (!open) {
      setIsPlaying(null);
    }
  };

  if (videos?.length < 1) {
    return <></>;
  } else {
    return (
      <div
        style={{ backgroundImage: `url(/images/bg.png)` }}
        className="w-full bg-scpb-dark-blue flex justify-center"
      >
        <div className="container front-page-videos-container flex flex-col px-2 lg:px-0 py-4 pb-0">
          {/* <div
            className={
              "transition-all cursor-pointer py-2 border-b-2 border-transparent text-slate-600 header-container"
            }
          >
            <Typography
              variant="paragraph"
              className={"font-bold text-2xl text-slate-900 text-white"}
            >
              {header}
              <span className={"highlight text-xl font-bold"}>&nbsp;/</span>
            </Typography>
          </div> */}
          <VideoGrid videos={videos} setIsPlaying={setIsPlaying} />
        </div>
        <VideoModal
          id={isPlaying}
          open={Boolean(isPlaying)}
          setOpen={handleModalOpen}
        />
      </div>
    );
  }
};

export default FrontPageVideos;
