import { Typography } from "@material-tailwind/react";
import Link from "next/link";
import React, { useState } from "react";
import VideoPlayer from "../../Videos/VideoPlayer";
import VideoModal from "../../Modal/Video";
import HTMLText from "../../ui/HTMLText";

export interface TopVideoCardProps {
  id: string;
  title: string;
  url: string;
  image: string;
  onClick: (id: string) => void;
}

const TopVideoCard = ({
  id,
  title,
  url,
  image,
  onClick,
}: TopVideoCardProps) => {
  return (
    <>
      <div
        className={"top-video-card cursor-pointer"}
        onClick={() => onClick(id)}
      >
        <div className={"image"} style={{ backgroundImage: `url(${image})` }} />
        <i className="play-button fa-solid fa-circle-play text-white bg-scpb-light-blue text-3xl"></i>
        <div
          className={
            "text-container flex flex-row items-center align-center gap-2"
          }
        >
          <p className={"title leading-tight font-bold mb-1 text-white"}>
            <HTMLText text={title} />
          </p>
        </div>
        {title && title !== "" && <div className={"article-bg-gradient"}></div>}
      </div>
    </>
  );
};

export default TopVideoCard;
