function decodeHTML(html: string): string {
  if (typeof window !== "undefined") {
    const elem = document?.createElement("textarea");
    elem.innerHTML = html;
    return elem.value;
  } else {
    return html;
  }
}
export interface HTMLTextProps {
  text: string;
}
const HTMLText = ({ text }: HTMLTextProps) => {
  const decodedString = decodeHTML(text);
  return <>{decodedString}</>;
};

export default HTMLText;
