import { YoutubeVideo } from "@/src/utils/youtube";
import React from "react";
import FrontPageVideos from "../../FrontPage/FrontPageVideos";

export interface VideosBlockProps {
  block: {
    frontPageVideosHeader: string;
  };
  videos: YoutubeVideo[];
}

const VideosBlock = (block: VideosBlockProps) => {
  return (
    <>
      <FrontPageVideos
        videos={block.videos}
        header={block.block.frontPageVideosHeader}
      />
    </>
  );
};

export default VideosBlock;
