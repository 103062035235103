import { Button, Typography } from "@material-tailwind/react";
import { color } from "@material-tailwind/react/types/components/button";
import React from "react";

export interface HeroArticleProps {
  title: string;
  subTitle: string;
  icon: string;
  button: {
    onClick: () => void;
    text: string;
    color: color;
  };
  bgImage: string;
}

const HeroArticle = (props: HeroArticleProps) => {
  return (
    <div className={"hero-article w-[100%] flex align-center"}>
      <div className="content-container flex content-center w-[100%] h-[100%] items-center flex-row text-white px-8">
        <div className={"title-container"}>
          <Typography variant="h3">
            <b>{props.title}</b>
          </Typography>
          <Typography variant={"lead"} as={"div"}>
            <div
              dangerouslySetInnerHTML={{ __html: props?.subTitle ?? {} }}
            ></div>
          </Typography>
          <Button
            variant="gradient"
            color={props.button.color}
            onClick={props.button.onClick}
          >
            {props.button.text}
          </Button>
        </div>
      </div>

      <div
        className={"bg-image"}
        style={{ backgroundImage: `url(${props.bgImage})` }}
      />
      <div className={"article-bg-gradient"} />
    </div>
  );
};

export default HeroArticle;
