import React from "react";
import HeroArticle from "../FrontPage/HeroArticle";
import { ArticleCardType } from "@/src/types";
import { useRouter } from "next/router";

export interface NodeImageType {
  node: {
    mediaDetails: {
      sizes: [
        {
          sourceUrl: string;
        }
      ];
    };
  };
}

export interface HeroArticleBlockProps {
  block: {
    heroArticleHeading: string;
    heroArticleSubTitle: string;
    heroArticlePostLink: ArticleCardType;
    heroArticleImage: NodeImageType;
    heroArticleIcon: NodeImageType;
    heroArticleButonText: string;
  };
}

const HeroArticleBlock = ({ block }: HeroArticleBlockProps) => {
  const router = useRouter();
  const icon = block.heroArticleIcon?.node?.mediaDetails?.sizes[0]?.sourceUrl;
  const bgImage =
    block.heroArticleImage?.node?.mediaDetails?.sizes[0]?.sourceUrl;

  const handleClick = () => {
    //@ts-ignore
    router.push(`/blog/${block.heroArticlePostLink.nodes[0].uri}`);
  };

  return (
    <HeroArticle
      title={block.heroArticleHeading}
      subTitle={block.heroArticleSubTitle}
      icon={icon}
      button={{
        onClick: handleClick,
        text: block.heroArticleButonText,
        color: "light-blue",
      }}
      bgImage={bgImage}
    />
  );
};

export default HeroArticleBlock;
